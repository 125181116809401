import axios from 'axios'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { InputWrap } from '../../../components/AddMerchantForm/AddMerchantForm.styles'
import { StoreContext } from '../../../components/App'
import { TextInput } from '../../../components/TextInput/TextInput'
import {
  CountrySelect,
  CountrySelectItem,
} from '../../../components/CountrySelect/CountrySelect'
import { findCountry } from '../../../components/CountrySelect/methods'
import { ToRight } from '../EnrollCompany.styles'
import {
  Container,
  FormSection,
  FormSectionOptional,
  InputRadio,
  ProceedButton,
} from './enroll.styles'
import { AnimatedBorder } from '../../../components/AnimatedInput/AnimatedBorder'
import { PageHeading } from '../../../components/PageHeading/PageHeading'

export const BusinessAddress = observer(() => {
  const navigate = useNavigate()
  const store = useContext(StoreContext)
  const { theme } = store.AppState
  const {
    formLabels: transForm,
    enrollCompanyPage: { businessAddress: trans },
  } = store.TranslationsState.translations

  const [isDisabled, setIsDisabled] = useState(true)
  const [isPolling, setIsPolling] = useState(true)

  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [country, setCountry] = useState<CountrySelectItem>({
    value: '',
    label: transForm.selectCountry,
  })

  const [address1Fetched, setAddress1Fetched] = useState(false)
  const [address2Fetched, setAddress2Fetched] = useState(false)
  const [cityFetched, setCityFetched] = useState(false)
  const [postalCodeFetched, setPostalCodeFetched] = useState(false)
  const [countryFetched, setCountryFetched] = useState(false)

  const [showTrading, setShowTrading] = useState(false)

  const [tradingAddress1, setTradingAddress1] = useState('')
  const [tradingAddress2, setTradingAddress2] = useState('')
  const [tradingCity, setTradingCity] = useState('')
  const [tradingPostalCode, setTradingPostalCode] = useState('')
  const [tradingCountry, setTradingCountry] = useState<CountrySelectItem>({
    value: '',
    label: transForm.selectCountry,
  })

  const [tradingAddress1Fetched, setTradingAddress1Fetched] = useState(false)
  const [tradingAddress2Fetched, setTradingAddress2Fetched] = useState(false)
  const [tradingCityFetched, setTradingCityFetched] = useState(false)
  const [tradingPostalCodeFetched, setTradingPostalCodeFetched] =
    useState(false)
  const [tradingCountryFetched, setTradingCountryFetched] = useState(false)

  const getMerchantAddress = async () => {
    try {
      setIsDisabled(true)

      const res = await axios.get(
        `${process.env.WEB_API_URL}/merchant-enrolment/merchants/addresses`,
        {
          withCredentials: true,
        }
      )

      if (res.data.companyInformationProviderAddresses) {
        const { registered, trading } =
          res.data.companyInformationProviderAddresses

        if (registered.line1) {
          setAddress1(registered.line1)
          setAddress1Fetched(true)
        }
        if (registered.line2) {
          setAddress2(registered.line2)
          setAddress2Fetched(true)
        }
        if (registered.city) {
          setCity(registered.city)
          setCityFetched(true)
        }
        if (registered.postcode) {
          setPostalCode(registered.postcode)
          setPostalCodeFetched(true)
        }
        if (registered.country) {
          setCountry(findCountry(registered.country))
          setCountryFetched(true)
        }

        if (trading.line1) {
          setTradingAddress1(trading.line1)
          setTradingAddress1Fetched(true)
        }
        if (trading.line2) {
          setTradingAddress2(trading.line2)
          setTradingAddress2Fetched(true)
        }
        if (trading.city) {
          setTradingCity(trading.city)
          setTradingCityFetched(true)
        }
        if (trading.postcode) {
          setTradingPostalCode(trading.postcode)
          setTradingPostalCodeFetched(true)
        }
        if (trading.country) {
          setTradingCountry(findCountry(trading.country))
          setTradingCountryFetched(true)
        }
      }

      setIsPolling(false)
      setTimeout(() => {
        setAddress1Fetched(false)
        setAddress2Fetched(false)
        setCityFetched(false)
        setPostalCodeFetched(false)
        setCountryFetched(false)

        setTradingAddress1Fetched(false)
        setTradingAddress2Fetched(false)
        setTradingCityFetched(false)
        setTradingPostalCodeFetched(false)
        setTradingCountryFetched(false)
      }, 500)
      setIsDisabled(false)
    } catch (e) {
      setIsDisabled(false)
      console.log(e)
    }
  }

  async function handleSubmit() {
    try {
      setIsDisabled(true)

      await axios.post(
        `${process.env.WEB_API_URL}/merchant-enrolment/merchants/addresses`,
        {
          registered: {
            line1: address1,
            line2: address2,
            city: city,
            postcode: postalCode,
            country: country.value,
          },
          trading: {
            line1: tradingAddress1,
            line2: tradingAddress2,
            city: tradingCity,
            postcode: tradingPostalCode,
            country: tradingCountry.value,
          },
        },
        {
          withCredentials: true,
        }
      )

      navigate('/enroll-company/directors')

      setIsDisabled(false)
    } catch (e) {
      setIsDisabled(false)
      console.log(e)
    }
  }

  useEffect(() => {
    if (isPolling) {
      const intervalId = setInterval(getMerchantAddress, 3000) // Poll every 3 seconds

      // Cleanup on unmount or when stopping polling
      return () => clearInterval(intervalId)
    }
  }, [isPolling])

  return (
    <Container>
      <PageHeading
        title={trans.title}
        subtitle={trans.subtitle}
        {...theme.pageHeading}
      />
      <form onSubmit={(e) => e.preventDefault()}>
        <h3>{trans.registrationAddress}</h3>
        <FormSection>
          <InputWrap>
            <label htmlFor="address1">{trans.address}</label>
            <AnimatedBorder
              isLoading={isPolling || isDisabled}
              isFilled={address1Fetched}
            >
              <TextInput
                type="text"
                value={address1}
                onChange={(e) => setAddress1(e.target.value)}
                name="address1"
                disabled={isPolling || isDisabled}
              />
            </AnimatedBorder>
          </InputWrap>
          <InputWrap>
            <label htmlFor="address2">
              {trans.address} 2 ({trans.optional})
            </label>
            <AnimatedBorder
              isLoading={isPolling || isDisabled}
              isFilled={address2Fetched}
            >
              <TextInput
                type="text"
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
                name="address2"
                disabled={isPolling || isDisabled}
              />
            </AnimatedBorder>
          </InputWrap>
          <InputWrap>
            <label htmlFor="city">{trans.city}</label>
            <AnimatedBorder
              isLoading={isPolling || isDisabled}
              isFilled={cityFetched}
            >
              <TextInput
                type="text"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                name="city"
                disabled={isPolling || isDisabled}
              />
            </AnimatedBorder>
          </InputWrap>
          <InputWrap>
            <label htmlFor="postalCode">{trans.postalCode}</label>
            <AnimatedBorder
              isLoading={isPolling || isDisabled}
              isFilled={postalCodeFetched}
            >
              <TextInput
                type="text"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                name="postalCode"
                disabled={isPolling || isDisabled}
              />
            </AnimatedBorder>
          </InputWrap>
          <InputWrap>
            <label htmlFor="country">{trans.companyCountry}</label>
            <AnimatedBorder
              isLoading={isPolling || isDisabled}
              isFilled={countryFetched}
            >
              <CountrySelect
                value={country}
                onChange={(val) => setCountry(val as CountrySelectItem)}
                name="country"
                disabled={isPolling || isDisabled}
              />
            </AnimatedBorder>
          </InputWrap>
        </FormSection>
        <FormSection>
          <h3>{trans.tradingAddress}</h3>
          <InputWrap>
            <label htmlFor="showTrading" onClick={() => setShowTrading(false)}>
              <InputRadio
                name="showTrading"
                type="radio"
                checked={!showTrading}
                onClick={() => setShowTrading(false)}
              />
              {trans.sameAsRegistrationAddress}
            </label>
          </InputWrap>
          <InputWrap>
            <label htmlFor="showTrading" onClick={() => setShowTrading(true)}>
              <InputRadio
                name="showTrading"
                type="radio"
                checked={showTrading}
                onClick={() => setShowTrading(true)}
              />
              {trans.differentThanRegistrationAddress}
            </label>
          </InputWrap>
        </FormSection>
        <FormSectionOptional visible={showTrading}>
          <InputWrap>
            <label htmlFor="tradingAddress1">{trans.address}</label>
            <AnimatedBorder
              isLoading={isPolling || isDisabled}
              isFilled={tradingAddress1Fetched}
            >
              <TextInput
                type="text"
                value={tradingAddress1}
                onChange={(e) => setTradingAddress1(e.target.value)}
                name="tradingAddress1"
                disabled={isPolling || isDisabled}
              />
            </AnimatedBorder>
          </InputWrap>
          <InputWrap>
            <label htmlFor="tradingAddress2">
              {trans.address} 2 ({trans.optional})
            </label>
            <AnimatedBorder
              isLoading={isPolling || isDisabled}
              isFilled={tradingAddress2Fetched}
            >
              <TextInput
                type="text"
                value={tradingAddress2}
                onChange={(e) => setTradingAddress2(e.target.value)}
                name="tradingAddress2"
                disabled={isPolling || isDisabled}
              />
            </AnimatedBorder>
          </InputWrap>
          <InputWrap>
            <label htmlFor="tradingCity">{trans.city}</label>
            <AnimatedBorder
              isLoading={isPolling || isDisabled}
              isFilled={tradingCityFetched}
            >
              <TextInput
                type="text"
                value={tradingCity}
                onChange={(e) => setTradingCity(e.target.value)}
                name="tradingCity"
                disabled={isPolling || isDisabled}
              />
            </AnimatedBorder>
          </InputWrap>
          <InputWrap>
            <label htmlFor="tradingPostalCode">{trans.postalCode}</label>
            <AnimatedBorder
              isLoading={isPolling || isDisabled}
              isFilled={tradingPostalCodeFetched}
            >
              <TextInput
                type="text"
                value={tradingPostalCode}
                onChange={(e) => setTradingPostalCode(e.target.value)}
                name="tradingPostalCode"
                disabled={isPolling || isDisabled}
              />
            </AnimatedBorder>
          </InputWrap>
          <InputWrap>
            <label htmlFor="tradingCountry">{trans.country}</label>
            <AnimatedBorder
              isLoading={isPolling || isDisabled}
              isFilled={tradingCountryFetched}
            >
              <CountrySelect
                value={tradingCountry}
                onChange={(val) => setTradingCountry(val as CountrySelectItem)}
                name="tradingCountry"
                disabled={isPolling || isDisabled}
              />
            </AnimatedBorder>
          </InputWrap>
        </FormSectionOptional>
        <ToRight>
          <ProceedButton
            margin="40px 0 0 0"
            onClick={() => handleSubmit()}
            disabled={isPolling || isDisabled}
          >
            {trans.continue}
          </ProceedButton>
        </ToRight>
      </form>
    </Container>
  )
})

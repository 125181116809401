/* eslint-disable */
import { useForm } from '@tanstack/react-form'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'

import { StoreContext } from '../../components/App'
import {
  Container,
  FormBottom,
  InputInfo,
  InputWrap,
} from './AddMerchantForm.styles'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'
import { Button } from '../../components/Button/Button'

function FieldInfo({ field }) {
  return (
    <InputInfo>
      {field.state.meta.touchedErrors ? (
        <em>{field.state.meta.touchedErrors}</em>
      ) : null}
      {field.state.meta.isValidating ? 'Validating...' : null}
    </InputInfo>
  )
}

interface AddMerchantFormProps {
  handleOnSend?: (props?: any) => void
}

export const AddMerchantForm = ({ handleOnSend }: AddMerchantFormProps) => {
  const store = useContext(StoreContext)
  const { theme } = store.AppState

  const [isLoading, setIsLoading] = useState(false)
  const form = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      companyName: '',
      companyNumber: '',
      companyCountry: 'United Kingdom',
      companyWebsite: '',
      signatoryRight: false,
      ultimateBeneficialOwner: false,
      directorOrController: false,
    },
    onSubmit: async ({ value }) => {
      // Do something with form data
      try {
        setIsLoading(true)
        const res = await axios.post(
          'https://merchant-poc-2577551f9ce2.herokuapp.com/merchants',
          {
            ...value,
            companyCountry: 'United Kingdom',
            signatoryRight: value.signatoryRight ? 'true' : 'false',
            ultimateBeneficialOwner: value.ultimateBeneficialOwner
              ? 'true'
              : 'false',
            directorOrController: value.directorOrController ? 'true' : 'false',
          },
          {
            withCredentials: true,
          }
        )
        setIsLoading(false)
        handleOnSend && handleOnSend()
        toast('Merchant created', { type: 'success' })
      } catch (e) {
        setIsLoading(false)
        handleOnSend && handleOnSend()
        toast('Something went wrong, please corrent your data and try again', {
          type: 'error',
        })
        console.log(e)
      }
    },
  })

  return (
    <Container>
      <h1>Add new Merchant</h1>
      {isLoading ? (
        <LoadingSpinner
          {...theme.loadingSpinner}
          height="calc(100% - 86px)"
          width="48px"
          bgColor="#fff"
          primary="rgba(90, 51, 139, 1)"
        />
      ) : (
        <>
          <form.Provider>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                e.stopPropagation()
                void form.handleSubmit()
              }}
            >
              <div>
                <form.Field
                  name="firstName"
                  validators={{
                    onChange: ({ value }) =>
                      !value
                        ? 'A first name is required'
                        : value.length < 3
                        ? 'First name must be at least 3 characters'
                        : undefined,
                  }}
                  children={(field) => {
                    return (
                      <InputWrap>
                        <label htmlFor={field.name}>First name</label>
                        <input
                          name={field.name}
                          value={field.state.value}
                          onBlur={field.handleBlur}
                          onChange={(e) => field.handleChange(e.target.value)}
                          type="text"
                        />
                        <FieldInfo field={field} />
                      </InputWrap>
                    )
                  }}
                />
              </div>
              <div>
                <form.Field
                  name="lastName"
                  validators={{
                    onChange: ({ value }) =>
                      !value
                        ? 'A last name is required'
                        : value.length < 2
                        ? 'First name must be at least 2 characters'
                        : undefined,
                  }}
                  children={(field) => {
                    return (
                      <InputWrap>
                        <label htmlFor={field.name}>Last name</label>
                        <input
                          name={field.name}
                          value={field.state.value}
                          onBlur={field.handleBlur}
                          onChange={(e) => field.handleChange(e.target.value)}
                          type="text"
                        />
                        <FieldInfo field={field} />
                      </InputWrap>
                    )
                  }}
                />
              </div>
              <div>
                <form.Field
                  name="email"
                  validators={{
                    onChange: ({ value }) =>
                      !value ? 'A e-mail is required' : undefined,
                  }}
                  children={(field) => {
                    return (
                      <InputWrap>
                        <label htmlFor={field.name}>Email</label>
                        <input
                          name={field.name}
                          value={field.state.value}
                          onBlur={field.handleBlur}
                          onChange={(e) => field.handleChange(e.target.value)}
                          type="text"
                        />
                        <FieldInfo field={field} />
                      </InputWrap>
                    )
                  }}
                />
              </div>
              <div>
                <form.Field
                  name="phoneNumber"
                  validators={{
                    onChange: ({ value }) =>
                      !value ? 'A phone number is required' : undefined,
                  }}
                  children={(field) => {
                    return (
                      <InputWrap>
                        <label htmlFor={field.name}>Phone number</label>
                        <input
                          name={field.name}
                          value={field.state.value}
                          onBlur={field.handleBlur}
                          onChange={(e) => field.handleChange(e.target.value)}
                          type="text"
                        />
                        <FieldInfo field={field} />
                      </InputWrap>
                    )
                  }}
                />
              </div>
              <div>
                <form.Field
                  name="companyName"
                  validators={{
                    onChange: ({ value }) =>
                      !value ? 'A company name is required' : undefined,
                  }}
                  children={(field) => {
                    return (
                      <InputWrap>
                        <label htmlFor={field.name}>Company name</label>
                        <input
                          name={field.name}
                          value={field.state.value}
                          onBlur={field.handleBlur}
                          onChange={(e) => field.handleChange(e.target.value)}
                          type="text"
                        />
                        <FieldInfo field={field} />
                      </InputWrap>
                    )
                  }}
                />
              </div>
              <div>
                <form.Field
                  name="companyNumber"
                  validators={{
                    onChange: ({ value }) =>
                      !value ? 'A company number is required' : undefined,
                  }}
                  children={(field) => {
                    return (
                      <InputWrap>
                        <label htmlFor={field.name}>Company number</label>
                        <input
                          name={field.name}
                          value={field.state.value}
                          onBlur={field.handleBlur}
                          onChange={(e) => field.handleChange(e.target.value)}
                          type="text"
                        />
                        <FieldInfo field={field} />
                      </InputWrap>
                    )
                  }}
                />
              </div>
              <div>
                <form.Field
                  name="companyCountry"
                  validators={{
                    onChange: ({ value }) =>
                      !value ? 'A company country is required' : undefined,
                  }}
                  children={(field) => {
                    return (
                      <InputWrap>
                        <label htmlFor={field.name}>Company country</label>
                        <input
                          name={field.name}
                          value={field.state.value}
                          onBlur={field.handleBlur}
                          onChange={(e) => field.handleChange(e.target.value)}
                          defaultValue={'United Kingdom'}
                          type="text"
                        />
                        <FieldInfo field={field} />
                      </InputWrap>
                    )
                  }}
                />
              </div>
              <div>
                <form.Field
                  name="companyWebsite"
                  validators={{
                    onChange: ({ value }) =>
                      !value ? 'A company website is required' : undefined,
                  }}
                  children={(field) => {
                    return (
                      <InputWrap>
                        <label htmlFor={field.name}>Company website URL</label>
                        <input
                          name={field.name}
                          value={field.state.value}
                          onBlur={field.handleBlur}
                          onChange={(e) => field.handleChange(e.target.value)}
                          type="text"
                        />
                        <FieldInfo field={field} />
                      </InputWrap>
                    )
                  }}
                />
              </div>
              <div>
                <form.Field
                  name="signatoryRight"
                  children={(field) => {
                    return (
                      <InputWrap>
                        <label htmlFor={field.name}>
                          <input
                            type="checkbox"
                            name={field.name}
                            id={field.name}
                            checked={field.state.value as any}
                            onBlur={field.handleBlur}
                            onChange={(e) =>
                              field.handleChange(e.target.checked as any)
                            }
                          />
                          <span>Signatory right</span>
                        </label>
                        <FieldInfo field={field} />
                      </InputWrap>
                    )
                  }}
                />
              </div>
              <div>
                <form.Field
                  name="ultimateBeneficialOwner"
                  children={(field) => {
                    return (
                      <InputWrap>
                        <label htmlFor={field.name}>
                          <input
                            type="checkbox"
                            name={field.name}
                            id={field.name}
                            checked={field.state.value as any}
                            onBlur={field.handleBlur}
                            onChange={(e) =>
                              field.handleChange(e.target.checked as any)
                            }
                          />
                          <span>Ultimate Beneficial Owner</span>
                        </label>
                        <FieldInfo field={field} />
                      </InputWrap>
                    )
                  }}
                />
              </div>
              <div>
                <form.Field
                  name="directorOrController"
                  children={(field) => {
                    return (
                      <InputWrap>
                        <label htmlFor={field.name}>
                          <input
                            type="checkbox"
                            name={field.name}
                            id={field.name}
                            checked={field.state.value as any}
                            onBlur={field.handleBlur}
                            onChange={(e) =>
                              field.handleChange(e.target.checked as any)
                            }
                          />
                          <span>Director or controller</span>
                        </label>
                        <FieldInfo field={field} />
                      </InputWrap>
                    )
                  }}
                />
              </div>
              <FormBottom>
                <Button {...theme.button} type="submit">
                  Add new merchant
                </Button>
              </FormBottom>
            </form>
          </form.Provider>
        </>
      )}
    </Container>
  )
}

import React from 'react'
import { Container } from './ErrorInfo.styles'

interface StatusInfoProps {
  children: React.ReactNode
  status?: string
}

export const ErrorInfo = ({ children, status }: StatusInfoProps) => {
  const statuses = {
    negative: {
      bgColor: '#FFE9EB',
      bulletColor: '#D8513F',
      color: '#A82626',
    },
    notice: {
      bgColor: '#FFF0E2',
      bulletColor: '#FB9600',
      color: '#995403',
    },
    positive: {
      bgColor: '#E0FCF5',
      bulletColor: '#2B633F',
      color: '#2B633F',
    },
  }

  return (
    <Container
      bgColor={statuses[status].bgColor}
      color={statuses[status].color}
    >
      {children}
    </Container>
  )
}

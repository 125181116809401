import React, { useState, useEffect, useContext } from 'react'
import {
  Category,
  CategoryName,
  CategoryOrb,
  CategoryWrap,
  Container,
  Step,
  StepName,
  StepOrb,
  Steps,
  StepsDivider,
} from './OnboardingWizard.styles'
import { observer } from 'mobx-react'
import { StoreContext } from '../../components/App'

interface WizardItem {
  name: string
  status: 'active' | 'done' | '' | string
  id?: string
  steps?: WizardItem[]
}

interface OnboardingWizardProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  data: WizardItem[]
  color?: string
  bgColor?: string
  borderColor?: string
  colorActive?: string
  bgColorActive?: string
  bgColorActiveStep?: string
  borderColorActive?: string
  colorDone?: string
  bgColorDone?: string
  borderColorDone?: string
  colorStep?: string
  colorStepActive?: string
  fontFamily?: string
  changeStep?: (id: string) => void
}

export const OnboardingWizard = observer(
  ({
    data,
    color,
    bgColor,
    borderColor,
    colorActive,
    bgColorActive,
    borderColorActive,
    colorDone,
    bgColorDone,
    borderColorDone,
    colorStep,
    colorStepActive,
    fontFamily,
    changeStep,
  }: OnboardingWizardProps) => {
    const store = useContext(StoreContext)
    const { translations } = store.TranslationsState
    const [currentData, setCurrentData] = useState([])

    useEffect(() => {
      setCurrentData(data)
    }, [data])

    const commonStyles = {
      color: color,
      bgColor: bgColor,
      borderColor: borderColor,
      colorActive: colorActive,
      bgColorActive: bgColorActive,
      borderColorActive: borderColorActive,
      colorDone: colorDone,
      bgColorDone: bgColorDone,
      borderColorDone: borderColorDone,
      colorStep: colorStep,
      colorStepActive: colorStepActive,
      fontFamily: fontFamily,
    }

    const getName = (name: string) => {
      const nameSplit = name.split('.')
      let transResolved = translations[nameSplit.shift()]

      nameSplit.forEach((split) => {
        transResolved = transResolved[split]
      })

      return transResolved || name
    }

    const handleChangeStep = (category) => {
      category.nav && category.status && category.id
        ? changeStep(category.id)
        : undefined
    }

    return (
      <Container>
        {currentData.map((category, index) => {
          return (
            <CategoryWrap key={category.name}>
              <Category>
                <CategoryOrb
                  status={category.status}
                  {...commonStyles}
                  onClick={() => handleChangeStep(category)}
                  clickable={category.status && category.id}
                >
                  {index + 1}
                </CategoryOrb>
                <CategoryName
                  status={category.status}
                  {...commonStyles}
                  onClick={() => handleChangeStep(category)}
                  clickable={category.status && category.id}
                >
                  {getName(category.name).toUpperCase()}
                </CategoryName>
              </Category>
              {category.steps && category.steps.length ? (
                <Steps>
                  <StepsDivider status={category.status} {...commonStyles} />
                  {category.steps.map((step, stepIndex) => {
                    return (
                      <div key={step.name}>
                        <Step>
                          <StepOrb
                            status={step.status}
                            {...commonStyles}
                            onClick={() => handleChangeStep(step)}
                            clickable={step.status && step.id}
                          />
                          <StepName
                            status={step.status}
                            {...commonStyles}
                            onClick={() => handleChangeStep(step)}
                            clickable={step.status && step.id}
                          >
                            {getName(step.name)}
                          </StepName>
                        </Step>
                        {stepIndex + 1 !== category.steps.length ? (
                          <StepsDivider
                            status={category.steps[stepIndex + 1].status}
                            {...commonStyles}
                          />
                        ) : (
                          <StepsDivider
                            status={currentData[index + 1].status}
                            {...commonStyles}
                          />
                        )}
                      </div>
                    )
                  })}
                </Steps>
              ) : (
                index + 1 !== currentData.length && <StepsDivider />
              )}
            </CategoryWrap>
          )
        })}
      </Container>
    )
  }
)

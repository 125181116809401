import styled from 'styled-components'

interface ContainerProps {
  padding?: string
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: ${(props) => (props.padding ? props.padding : '30px')};
`

export const CategoryWrap = styled.div`
  width: 100%;
`

export const Category = styled.div`
  width: 100%;
  display: flex;
  margin: 4px 0;
`

interface CategoryOrbProps {
  status?: string
  color?: string
  bgColor?: string
  borderColor?: string
  colorActive?: string
  bgColorActive?: string
  borderColorActive?: string
  colorDone?: string
  bgColorDone?: string
  borderColorDone?: string
  colorStep?: string
  colorStepActive?: string
  fontFamily?: string
  clickable?: boolean
}

export const CategoryOrb = styled.div<CategoryOrbProps>`
  width: 40px;
  height: 40px;
  border: 1.5px solid
    ${(props) =>
      props.borderColorActive ? props.borderColorActive : '#5A338B'};
  border-color: ${(props) =>
    props.status === ''
      ? props.borderColor
        ? props.borderColor
        : '#C6C3C3'
      : props.status === 'active'
      ? props.borderColorActive
        ? props.borderColorActive
        : '#5A338B'
      : props.status === 'done'
      ? props.borderColorDone
        ? props.borderColorDone
        : '#5A338B'
      : props.borderColorActive
      ? props.borderColorActive
      : '#5A338B'};
  background-color: ${(props) =>
    props.status === ''
      ? props.bgColor
        ? props.bgColor
        : '#F5F4F3'
      : props.status === 'active'
      ? props.bgColorActive
        ? props.bgColorActive
        : '#fff'
      : props.status === 'done'
      ? props.bgColorDone
        ? props.bgColorDone
        : '#5A338B'
      : props.bgColorActive
      ? props.bgColorActive
      : '#fff'};
  color: ${(props) =>
    props.status === ''
      ? props.color
        ? props.color
        : '#767676'
      : props.status === 'active'
      ? props.colorActive
        ? props.colorActive
        : '#5A338B'
      : props.status === 'done'
      ? props.colorDone
        ? props.colorDone
        : '#fff'
      : props.colorActive
      ? props.colorActive
      : '#5A338B'};
  text-align: center;
  border-radius: 40px;
  line-height: 40px;
  margin-right: 15px;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
`

export const CategoryName = styled.div<CategoryOrbProps>`
  line-height: 44px;
  ${(props) => props.fontFamily || ''};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: ${(props) =>
    props.status === ''
      ? props.colorStep
        ? props.colorStep
        : '#A19EAD'
      : props.colorStepActive
      ? props.colorStepActive
      : '#272142'};
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
`

export const Steps = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const StepsDivider = styled.div<CategoryOrbProps>`
  height: 25px;
  width: 2px;
  border-radius: 3px;
  background-color: ${(props) =>
    props.status === ''
      ? props.bgColor
        ? props.borderColor
        : '#C6C3C3'
      : props.status === 'active'
      ? props.bgColorDone
        ? props.bgColorDone
        : '#5A338B'
      : props.status === 'done'
      ? props.bgColorDone
        ? props.bgColorDone
        : '#5A338B'
      : props.borderColor
      ? props.borderColor
      : '#C6C3C3'};
  margin: 4px 20px;
`

export const Step = styled.div`
  width: 100%;
  display: flex;
`

export const StepOrb = styled.div<CategoryOrbProps>`
  width: 16px;
  height: 16px;
  border: 1.5px solid
    ${(props) =>
      props.borderColorActive ? props.borderColorActive : '#5A338B'};
  border-color: ${(props) =>
    props.status === ''
      ? props.borderColor
        ? props.borderColor
        : '#C6C3C3'
      : props.status === 'active'
      ? props.borderColorActive
        ? props.borderColorActive
        : '#5A338B'
      : props.status === 'done'
      ? props.borderColorDone
        ? props.borderColorDone
        : '#5A338B'
      : props.borderColorActive
      ? props.borderColorActive
      : '#5A338B'};
  background-color: ${(props) =>
    props.status === ''
      ? props.bgColor
        ? props.bgColor
        : '#F5F4F3'
      : props.status === 'active'
      ? props.bgColorActive
        ? props.bgColorActive
        : '#fff'
      : props.status === 'done'
      ? props.bgColorDone
        ? props.bgColorDone
        : '#5A338B'
      : props.bgColorActive
      ? props.bgColorActive
      : '#fff'};
  color: ${(props) =>
    props.status === ''
      ? props.color
        ? props.color
        : '#767676'
      : props.status === 'active'
      ? props.colorActive
        ? props.colorActive
        : '#5A338B'
      : props.status === 'done'
      ? props.colorDone
        ? props.colorDone
        : '#fff'
      : props.colorActive
      ? props.colorActive
      : '#5A338B'};
  border-radius: 16px;
  margin: 4px 11px;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
`

export const StepName = styled.div<CategoryOrbProps>`
  font-size: 12px;
  line-height: 28px;
  color: ${(props) =>
    props.status === ''
      ? props.colorStep
        ? props.colorStep
        : '#A19EAD'
      : props.colorStepActive
      ? props.colorStepActive
      : '#272142'};
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
`

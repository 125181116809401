import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { PageHeading } from '../../../components/PageHeading/PageHeading'
import { StoreContext } from '../../../components/App'
import {
  AddNewUbosContainer,
  Boxed,
  Container,
  ProceedButton,
} from './enroll.styles'
import IconPlus from '../../../assets/icon_plus_blue.svg'
import { AssignDirectorInput } from '../../../components/AssignDirectorInput/AssignDirectorInput'
import { Actor, blankActor, infoOnlyRequiredFields } from './Directors'
import { PersonCard } from '../../../components/PersonCard/PersonCard'
import { PersonCardAdd } from '../../../components/PersonCard/PersonCardAdd'
import { ToRight } from '../EnrollCompany.styles'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { ErrorInfo } from '../../../components/ErrorInfo/ErrorInfo'

let nextId = 0

export const Ubos = observer(() => {
  const navigate = useNavigate()
  const store = useContext(StoreContext)
  const { theme } = store.AppState
  const {
    enrollCompanyPage: { ubos: trans },
  } = store.TranslationsState.translations

  const [isDisabled, setIsDisabled] = useState(false)
  const [isProceedDisabled, setIsProceedDisabled] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>(null)
  const [people, setPeople] = useState<Actor[]>([])
  const [uboDirectors, setUboDirectors] = useState<number[]>([])

  const addDirectorToUbosHandler = (ids) => {
    setUboDirectors(ids)
  }

  const handleAddPerson = () => {
    setPeople([
      ...people,
      {
        ...blankActor,
        actorId: `new${nextId++}`,
        isDirector: false,
        isUbo: true,
        isNew: true,
      },
    ])
  }

  const handleDeletePerson = (id: number) => {
    console.log('delete')
  }

  const handleChangePerson = (actor: Actor) => {
    console.log(actor)

    setPeople((prevItems) =>
      prevItems.map((person) =>
        person.actorId === actor.actorId ? actor : person
      )
    )
  }

  const handleProceed = async () => {
    try {
      setIsDisabled(true)

      await axios.post(
        `${process.env.WEB_API_URL}/merchant-enrolment/actors`,
        {
          edit: people
            .filter((person) => !person.isApplicant && !person.isNew)
            .map((person) => {
              return {
                ...person,
                info: {
                  ...(person.info ? person.info : blankActor.info),
                  infix: person.info?.infix || '',
                  dateOfBirth:
                    person.info?.dateOfBirth ||
                    person.providerInfo?.dateOfBirth ||
                    0,
                },
                isUbo: person.isUbo
                  ? person.isUbo
                  : uboDirectors[person.actorId] || false,
              }
            }),
          add: people
            .filter(
              (person) =>
                person.isNew &&
                Object.values(infoOnlyRequiredFields(person.info)).every(
                  (x) => x !== null && x !== '' && x !== 0
                )
            )
            .map((person) => {
              return {
                ...Object.fromEntries(
                  Object.entries(person).filter(
                    ([key, value]) => key !== 'actorId' && key !== 'isNew'
                  )
                ),
                info: {
                  ...person.info,
                  infix: person.info?.infix || '',
                },
              }
            }),
        },
        {
          withCredentials: true,
        }
      )

      setIsDisabled(false)
      navigate('/enroll-company/summary')
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        setErrorMessage(e.response.data.message)

        setTimeout(() => setErrorMessage(null), 3000)
      }
      setIsDisabled(false)
      console.log(e)
    }
  }

  const getActors = async () => {
    try {
      setIsDisabled(true)
      const res = await axios.get(
        `${process.env.WEB_API_URL}/merchant-enrolment/actors`,
        { withCredentials: true }
      )

      if (res.data) {
        console.log(res.data)

        setPeople(res.data.actors)
      }

      setIsDisabled(false)
    } catch (e) {
      setIsDisabled(false)
      console.log(e)
    }
  }

  useEffect(() => {
    getActors()
  }, [])

  useEffect(() => {
    setIsProceedDisabled(true)
    const isRequiredDataSet = people
      .filter((person) => person.isUbo)
      .every((person) =>
        Object.values(infoOnlyRequiredFields(person.info)).every(
          (x) => x !== null && x !== '' && x !== 0
        )
      )

    if (isRequiredDataSet) setIsProceedDisabled(false)
  }, [people])

  return (
    <Container>
      <PageHeading
        title={trans.title}
        subtitle={trans.subtitle}
        {...theme.pageHeading}
      />
      {errorMessage && <ErrorInfo status="negative">{errorMessage}</ErrorInfo>}
      <Boxed>
        {people
          .filter((person) => person.isUbo && !person.isNew)
          .map((person) => (
            <PersonCard
              key={person.actorId}
              person={person}
              onChange={handleChangePerson}
              onDelete={handleDeletePerson}
              initialEdit={true}
              isLoading={isDisabled}
            />
          ))}

        {people
          .filter((person) => person.isNew)
          .map((person) => (
            <PersonCardAdd
              key={person.actorId}
              person={person}
              onChange={handleChangePerson}
              isLoading={isDisabled}
            />
          ))}

        <AssignDirectorInput
          directors={people.filter(
            (person) => person.isDirector && !person.isUbo && !person.isNew
          )}
          onChange={addDirectorToUbosHandler}
        />

        <AddNewUbosContainer>
          <h5>Add a new UBO’s</h5>
          <p>Click here to add a new UBO. Provide all required data.</p>
          <button onClick={handleAddPerson}>
            <img src={IconPlus} alt="Add" />
            {trans.addNewUbo}
          </button>
        </AddNewUbosContainer>

        <ToRight>
          <ProceedButton onClick={handleProceed} disabled={isProceedDisabled}>
            <span>{trans.proceed}</span>
          </ProceedButton>
        </ToRight>
      </Boxed>
    </Container>
  )
})

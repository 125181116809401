import { SelectItem } from '../components/SimpleSelect/SimpleSelect'

export const gicsList: SelectItem[] = [
  {
    value: '10',
    label: 'Energy',
  },
  {
    value: '1010',
    label: 'Energy',
  },
  {
    value: '101010',
    label: 'Energy Equipment & Services',
  },
  {
    value: '10101010',
    label: 'Oil & Gas Drilling',
  },
  {
    value: '10101020',
    label: 'Oil & Gas Equipment & Services',
  },
  {
    value: '101020',
    label: 'Oil, Gas & Consumable Fuels',
  },
  {
    value: '10102010',
    label: 'Integrated Oil & Gas',
  },
  {
    value: '10102020',
    label: 'Oil & Gas Exploration & Production',
  },
  {
    value: '10102030',
    label: 'Oil & Gas Refining & Marketing',
  },
  {
    value: '10102040',
    label: 'Oil & Gas Storage & Transportation',
  },
  {
    value: '10102050',
    label: 'Coal & Consumable Fuels',
  },
  {
    value: '15',
    label: 'Materials',
  },
  {
    value: '1510',
    label: 'Materials',
  },
  {
    value: '151010',
    label: 'Chemicals',
  },
  {
    value: '15101010',
    label: 'Commodity Chemicals',
  },
  {
    value: '15101020',
    label: 'Diversified Chemicals',
  },
  {
    value: '15101030',
    label: 'Fertilizers & Agricultural Chemicals',
  },
  {
    value: '15101040',
    label: 'Industrial Gases',
  },
  {
    value: '15101050',
    label: 'Specialty Chemicals',
  },
  {
    value: '151020',
    label: 'Construction Materials',
  },
  {
    value: '15102010',
    label: 'Construction Materials',
  },
  {
    value: '151030',
    label: 'Containers & Packaging',
  },
  {
    value: '15103010',
    label: 'Metal & Glass Containers',
  },
  {
    value: '15103020',
    label: 'Paper Packaging',
  },
  {
    value: '151040',
    label: 'Metals & Mining',
  },
  {
    value: '15104010',
    label: 'Aluminum',
  },
  {
    value: '15104020',
    label: 'Diversified Metals & Mining',
  },
  {
    value: '15104025',
    label: 'Copper',
  },
  {
    value: '15104030',
    label: 'Gold',
  },
  {
    value: '15104040',
    label: 'Precious Metals & Minerals',
  },
  {
    value: '15104045',
    label: 'Silver',
  },
  {
    value: '15104050',
    label: 'Steel',
  },
  {
    value: '151050',
    label: 'Paper & Forest Products',
  },
  {
    value: '15105010',
    label: 'Forest Products',
  },
  {
    value: '15105020',
    label: 'Paper Products',
  },
  {
    value: '20',
    label: 'Industrials',
  },
  {
    value: '2010',
    label: 'Capital Goods',
  },
  {
    value: '201010',
    label: 'Aerospace & Defense',
  },
  {
    value: '20101010',
    label: 'Aerospace & Defense',
  },
  {
    value: '201020',
    label: 'Building Products',
  },
  {
    value: '20102010',
    label: 'Building Products',
  },
  {
    value: '201030',
    label: 'Construction & Engineering',
  },
  {
    value: '20103010',
    label: 'Construction & Engineering',
  },
  {
    value: '201040',
    label: 'Electrical Equipment',
  },
  {
    value: '20104010',
    label: 'Electrical Components & Equipment',
  },
  {
    value: '20104020',
    label: 'Heavy Electrical Equipment',
  },
  {
    value: '201050',
    label: 'Industrial Conglomerates',
  },
  {
    value: '20105010',
    label: 'Industrial Conglomerates',
  },
  {
    value: '201060',
    label: 'Machinery',
  },
  {
    value: '20106010',
    label: 'Construction Machinery & Heavy Trucks',
  },
  {
    value: '20106015',
    label: 'Agricultural & Farm Machinery',
  },
  {
    value: '20106020',
    label: 'Industrial Machinery',
  },
  {
    value: '201070',
    label: 'Trading Companies & Distributors',
  },
  {
    value: '20107010',
    label: 'Trading Companies & Distributors',
  },
  {
    value: '2020',
    label: 'Commercial & Professional Services',
  },
  {
    value: '202010',
    label: 'Commercial Services & Supplies',
  },
  {
    value: '20201010',
    label: 'Commercial Printing',
  },
  {
    value: '20201050',
    label: 'Environmental & Facilities Services',
  },
  {
    value: '20201060',
    label: 'Office Services & Supplies',
  },
  {
    value: '20201070',
    label: 'Diversified Support Services',
  },
  {
    value: '20201080',
    label: 'Security & Alarm Services',
  },
  {
    value: '202020',
    label: 'Professional Services',
  },
  {
    value: '20202010',
    label: 'Human Resource & Employment Services',
  },
  {
    value: '20202020',
    label: 'Research & Consulting Services',
  },
  {
    value: '2030',
    label: 'Transportation',
  },
  {
    value: '203010',
    label: 'Air Freight & Logistics',
  },
  {
    value: '20301010',
    label: 'Air Freight & Logistics',
  },
  {
    value: '203020',
    label: 'Airlines',
  },
  {
    value: '20302010',
    label: 'Airlines',
  },
  {
    value: '203030',
    label: 'Marine',
  },
  {
    value: '20303010',
    label: 'Marine',
  },
  {
    value: '203040',
    label: 'Road & Rail',
  },
  {
    value: '20304010',
    label: 'Railroads',
  },
  {
    value: '20304020',
    label: 'Trucking',
  },
  {
    value: '203050',
    label: 'Transportation Infrastructure',
  },
  {
    value: '20305010',
    label: 'Airport Services',
  },
  {
    value: '20305020',
    label: 'Highways & Railtracks',
  },
  {
    value: '20305030',
    label: 'Marine Ports & Services',
  },
  {
    value: '25',
    label: 'Consumer Discretionary',
  },
  {
    value: '2510',
    label: 'Automobiles & Components',
  },
  {
    value: '251010',
    label: 'Auto Components',
  },
  {
    value: '25101010',
    label: 'Auto Parts & Equipment',
  },
  {
    value: '25101020',
    label: 'Tires & Rubber',
  },
  {
    value: '251020',
    label: 'Automobiles',
  },
  {
    value: '25102010',
    label: 'Automobile Manufacturers',
  },
  {
    value: '25102020',
    label: 'Motorcycle Manufacturers',
  },
  {
    value: '2520',
    label: 'Consumer Durables & Apparel',
  },
  {
    value: '252010',
    label: 'Household Durables',
  },
  {
    value: '25201010',
    label: 'Consumer Electronics',
  },
  {
    value: '25201020',
    label: 'Home Furnishings',
  },
  {
    value: '25201030',
    label: 'Homebuilding',
  },
  {
    value: '25201040',
    label: 'Household Appliances',
  },
  {
    value: '25201050',
    label: 'Housewares & Specialties',
  },
  {
    value: '252020',
    label: 'Leisure Products',
  },
  {
    value: '25202010',
    label: 'Leisure Products',
  },
  {
    value: '252030',
    label: 'Textiles, Apparel & Luxury Goods',
  },
  {
    value: '25203010',
    label: 'Apparel, Accessories & Luxury Goods',
  },
  {
    value: '25203020',
    label: 'Footwear',
  },
  {
    value: '25203030',
    label: 'Textiles',
  },
  {
    value: '2530',
    label: 'Consumer Services',
  },
  {
    value: '253010',
    label: 'Hotels, Restaurants & Leisure',
  },
  {
    value: '25301010',
    label: 'Casinos & Gaming',
  },
  {
    value: '25301020',
    label: 'Hotels, Resorts & Cruise Lines',
  },
  {
    value: '25301030',
    label: 'Leisure Facilities',
  },
  {
    value: '25301040',
    label: 'Restaurants',
  },
  {
    value: '253020',
    label: 'Diversified Consumer Services',
  },
  {
    value: '25302010',
    label: 'Education Services',
  },
  {
    value: '25302020',
    label: 'Specialized Consumer Services',
  },
  {
    value: '2550',
    label: 'Retailing',
  },
  {
    value: '255010',
    label: 'Distributors',
  },
  {
    value: '25501010',
    label: 'Distributors',
  },
  {
    value: '255020',
    label: 'Internet & Direct Marketing Retail',
  },
  {
    value: '25502020',
    label: 'Internet & Direct Marketing Retail',
  },
  {
    value: '255030',
    label: 'Multiline Retail',
  },
  {
    value: '25503010',
    label: 'Department Stores',
  },
  {
    value: '25503020',
    label: 'General Merchandise Stores',
  },
  {
    value: '255040',
    label: 'Specialty Retail',
  },
  {
    value: '25504010',
    label: 'Apparel Retail',
  },
  {
    value: '25504020',
    label: 'Computer & Electronics Retail',
  },
  {
    value: '25504030',
    label: 'Home Improvement Retail',
  },
  {
    value: '25504040',
    label: 'Specialty Stores',
  },
  {
    value: '25504050',
    label: 'Automotive Retail',
  },
  {
    value: '25504060',
    label: 'Homefurnishing Retail',
  },
  {
    value: '30',
    label: 'Consumer Staples',
  },
  {
    value: '3010',
    label: 'Food & Staples Retailing',
  },
  {
    value: '301010',
    label: 'Food & Staples Retailing',
  },
  {
    value: '30101010',
    label: 'Drug Retail',
  },
  {
    value: '30101020',
    label: 'Food Distributors',
  },
  {
    value: '30101030',
    label: 'Food Retail',
  },
  {
    value: '30101040',
    label: 'Hypermarkets & Super Centers',
  },
  {
    value: '3020',
    label: 'Food, Beverage & Tobacco',
  },
  {
    value: '302010',
    label: 'Beverages',
  },
  {
    value: '30201010',
    label: 'Brewers',
  },
  {
    value: '30201020',
    label: 'Distillers & Vintners',
  },
  {
    value: '30201030',
    label: 'Soft Drinks',
  },
  {
    value: '302020',
    label: 'Food Products',
  },
  {
    value: '30202010',
    label: 'Agricultural Products',
  },
  {
    value: '30202030',
    label: 'Packaged Foods & Meats',
  },
  {
    value: '302030',
    label: 'Tobacco',
  },
  {
    value: '30203010',
    label: 'Tobacco',
  },
  {
    value: '3030',
    label: 'Household & Personal Products',
  },
  {
    value: '303010',
    label: 'Household Products',
  },
  {
    value: '30301010',
    label: 'Household Products',
  },
  {
    value: '303020',
    label: 'Personal Products',
  },
  {
    value: '30302010',
    label: 'Personal Products',
  },
  {
    value: '35',
    label: 'Health Care',
  },
  {
    value: '3510',
    label: 'Health Care Equipment & Services',
  },
  {
    value: '351010',
    label: 'Health Care Equipment & Supplies',
  },
  {
    value: '35101010',
    label: 'Health Care Equipment',
  },
  {
    value: '35101020',
    label: 'Health Care Supplies',
  },
  {
    value: '351020',
    label: 'Health Care Provvalueers & Services',
  },
  {
    value: '35102010',
    label: 'Health Care Distributors',
  },
  {
    value: '35102015',
    label: 'Health Care Services',
  },
  {
    value: '35102020',
    label: 'Health Care Facilities',
  },
  {
    value: '35102030',
    label: 'Managed Health Care',
  },
  {
    value: '351030',
    label: 'Health Care Technology',
  },
  {
    value: '35103010',
    label: 'Health Care Technology',
  },
  {
    value: '3520',
    label: 'Pharmaceuticals, Biotechnology & Life Sciences',
  },
  {
    value: '352010',
    label: 'Biotechnology',
  },
  {
    value: '35201010',
    label: 'Biotechnology',
  },
  {
    value: '352020',
    label: 'Pharmaceuticals',
  },
  {
    value: '35202010',
    label: 'Pharmaceuticals',
  },
  {
    value: '352030',
    label: 'Life Sciences Tools & Services',
  },
  {
    value: '35203010',
    label: 'Life Sciences Tools & Services',
  },
  {
    value: '40',
    label: 'Financials',
  },
  {
    value: '4010',
    label: 'Banks',
  },
  {
    value: '401010',
    label: 'Banks',
  },
  {
    value: '40101010',
    label: 'Diversified Banks',
  },
  {
    value: '40101015',
    label: 'Regional Banks',
  },
  {
    value: '401020',
    label: 'Thrifts & Mortgage Finance',
  },
  {
    value: '40102010',
    label: 'Thrifts & Mortgage Finance',
  },
  {
    value: '4020',
    label: 'Diversified Financials',
  },
  {
    value: '402010',
    label: 'Diversified Financial Services',
  },
  {
    value: '40201020',
    label: 'Other Diversified Financial Services',
  },
  {
    value: '40201030',
    label: 'Multi-Sector Holdings',
  },
  {
    value: '40201040',
    label: 'Specialized Finance',
  },
  {
    value: '402020',
    label: 'Consumer Finance',
  },
  {
    value: '40202010',
    label: 'Consumer Finance',
  },
  {
    value: '402030',
    label: 'Capital Markets',
  },
  {
    value: '40203010',
    label: 'Asset Management & Custody Banks',
  },
  {
    value: '40203020',
    label: 'Investment Banking & Brokerage',
  },
  {
    value: '40203030',
    label: 'Diversified Capital Markets',
  },
  {
    value: '40203040',
    label: 'Financial Exchanges & Data',
  },
  {
    value: '402040',
    label: 'Mortgage Real Estate Investment Trusts (REITs)',
  },
  {
    value: '40204010',
    label: 'Mortgage REITs',
  },
  {
    value: '4030',
    label: 'Insurance',
  },
  {
    value: '403010',
    label: 'Insurance',
  },
  {
    value: '40301010',
    label: 'Insurance Brokers',
  },
  {
    value: '40301020',
    label: 'Life & Health Insurance',
  },
  {
    value: '40301030',
    label: 'Multi-line Insurance',
  },
  {
    value: '40301040',
    label: 'Property & Casualty Insurance',
  },
  {
    value: '40301050',
    label: 'Reinsurance',
  },
  {
    value: '45',
    label: 'Information Technology',
  },
  {
    value: '4510',
    label: 'Software & Services',
  },
  {
    value: '451020',
    label: 'IT Services',
  },
  {
    value: '45102010',
    label: 'IT Consulting & Other Services',
  },
  {
    value: '45102020',
    label: 'Data Processing & Outsourced Services',
  },
  {
    value: '45102030',
    label: 'Internet Services & Infrastructure',
  },
  {
    value: '451030',
    label: 'Software',
  },
  {
    value: '45103010',
    label: 'Application Software',
  },
  {
    value: '45103020',
    label: 'Systems Software',
  },
  {
    value: '4520',
    label: 'Technology Hardware & Equipment',
  },
  {
    value: '452010',
    label: 'Communications Equipment',
  },
  {
    value: '45201020',
    label: 'Communications Equipment',
  },
  {
    value: '452020',
    label: 'Technology Hardware, Storage & Peripherals',
  },
  {
    value: '45202030',
    label: 'Technology Hardware, Storage & Peripherals',
  },
  {
    value: '452030',
    label: 'Electronic Equipment, Instruments & Components',
  },
  {
    value: '45203010',
    label: 'Electronic Equipment & Instruments',
  },
  {
    value: '45203015',
    label: 'Electronic Components',
  },
  {
    value: '45203020',
    label: 'Electronic Manufacturing Services',
  },
  {
    value: '45203030',
    label: 'Technology Distributors',
  },
  {
    value: '4530',
    label: 'Semiconductors & Semiconductor Equipment',
  },
  {
    value: '453010',
    label: 'Semiconductors & Semiconductor Equipment',
  },
  {
    value: '45301010',
    label: 'Semiconductor Equipment',
  },
  {
    value: '45301020',
    label: 'Semiconductors',
  },
  {
    value: '50',
    label: 'Communication Services',
  },
  {
    value: '5010',
    label: 'Communication Services',
  },
  {
    value: '501010',
    label: 'Diversified Telecommunication Services',
  },
  {
    value: '50101010',
    label: 'Alternative Carriers',
  },
  {
    value: '50101020',
    label: 'Integrated Telecommunication Services',
  },
  {
    value: '501020',
    label: 'Wireless Telecommunication Services',
  },
  {
    value: '50102010',
    label: 'Wireless Telecommunication Services',
  },
  {
    value: '5020',
    label: 'Media & Entertainment',
  },
  {
    value: '502010',
    label: 'Media',
  },
  {
    value: '50201010',
    label: 'Advertising',
  },
  {
    value: '50201020',
    label: 'Broadcasting',
  },
  {
    value: '50201030',
    label: 'Cable & Satellite',
  },
  {
    value: '50201040',
    label: 'Publishing',
  },
  {
    value: '502020',
    label: 'Entertainment',
  },
  {
    value: '50202010',
    label: 'Movies & Entertainment',
  },
  {
    value: '50202020',
    label: 'Interactive Home Entertainment',
  },
  {
    value: '502030',
    label: 'Interactive Media & Services',
  },
  {
    value: '50203010',
    label: 'Interactive Media & Services',
  },
  {
    value: '55',
    label: 'Utilities',
  },
  {
    value: '5510',
    label: 'Utilities',
  },
  {
    value: '551010',
    label: 'Electric Utilities',
  },
  {
    value: '55101010',
    label: 'Electric Utilities',
  },
  {
    value: '551020',
    label: 'Gas Utilities',
  },
  {
    value: '55102010',
    label: 'Gas Utilities',
  },
  {
    value: '551030',
    label: 'Multi-Utilities',
  },
  {
    value: '55103010',
    label: 'Multi-Utilities',
  },
  {
    value: '551040',
    label: 'Water Utilities',
  },
  {
    value: '55104010',
    label: 'Water Utilities',
  },
  {
    value: '551050',
    label: 'Independent Power and Renewable Electricity Producers',
  },
  {
    value: '55105010',
    label: 'Independent Power Producers & Energy Traders',
  },
  {
    value: '55105020',
    label: 'Renewable Electricity',
  },
  {
    value: '60',
    label: 'Real Estate',
  },
  {
    value: '6010',
    label: 'Real Estate',
  },
  {
    value: '601010',
    label: 'Equity Real Estate Investment Trusts (REITs)',
  },
  {
    value: '60101010',
    label: 'Diversified REITs',
  },
  {
    value: '60101020',
    label: 'Industrial REITs',
  },
  {
    value: '60101030',
    label: 'Hotel & Resort REITs',
  },
  {
    value: '60101040',
    label: 'Office REITs',
  },
  {
    value: '60101050',
    label: 'Health Care REITs',
  },
  {
    value: '60101060',
    label: 'Resvalueential REITs',
  },
  {
    value: '60101070',
    label: 'Retail REITs',
  },
  {
    value: '60101080',
    label: 'Specialized REITs',
  },
  {
    value: '601020',
    label: 'Real Estate Management & Development',
  },
  {
    value: '60102010',
    label: 'Diversified Real Estate Activities',
  },
  {
    value: '60102020',
    label: 'Real Estate Operating Companies',
  },
  {
    value: '60102030',
    label: 'Real Estate Development',
  },
  {
    value: '60102040',
    label: 'Real Estate Services',
  },
]

import React, { useContext } from 'react'
import { Container, InputWrap, Asterisk } from './PersonCard.styles'
import { AnimatedBorder } from '../AnimatedInput/AnimatedBorder'
import { observer } from 'mobx-react'
import { StoreContext } from '../App'
import { Actor } from '../../pages/EnrollCompany/subpages/Directors'

import { CountrySelect } from '../CountrySelect/CountrySelect'
import { getFormattedDate } from './PersonCard'

interface Person {
  actorId: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any // To allow dynamic properties
}

interface PersonProps {
  person: Person
  onChange: (actor: Actor) => void
  isLoading: boolean
}

export const PersonCardAdd: React.FC<PersonProps> = observer(
  ({ person, onChange, isLoading }) => {
    const store = useContext(StoreContext)

    const {
      formLabels: transForm,
      enrollCompanyPage: { summary: trans },
    } = store.TranslationsState.translations

    return (
      <Container>
        <InputWrap>
          <label htmlFor="firstName">
            {transForm.firstName} <Asterisk>*</Asterisk>
          </label>
          <AnimatedBorder isLoading={isLoading}>
            <input
              name="firstName"
              type="text"
              value={
                person.info && person.info.firstName
                  ? person.info.firstName
                  : ''
              }
              onChange={(e) =>
                onChange({
                  ...person,
                  info: {
                    ...person.info,
                    firstName: e.target.value,
                  },
                })
              }
            />
          </AnimatedBorder>
        </InputWrap>
        <InputWrap>
          <label htmlFor="infix">{transForm.infix}</label>
          <AnimatedBorder isLoading={isLoading}>
            <input
              name="infix"
              type="text"
              value={person.info && person.info.infix ? person.info.infix : ''}
              onChange={(e) =>
                onChange({
                  ...person,
                  info: {
                    ...person.info,
                    infix: e.target.value,
                  },
                })
              }
            />
          </AnimatedBorder>
        </InputWrap>
        <InputWrap>
          <label htmlFor="lastName">
            {transForm.lastName} <Asterisk>*</Asterisk>
          </label>
          <AnimatedBorder isLoading={isLoading}>
            <input
              name="lastName"
              type="text"
              value={
                person.info && person.info.lastName ? person.info.lastName : ''
              }
              onChange={(e) =>
                onChange({
                  ...person,
                  info: {
                    ...person.info,
                    lastName: e.target.value,
                  },
                })
              }
            />
          </AnimatedBorder>
        </InputWrap>
        <InputWrap>
          <label htmlFor="phoneNumber">
            {transForm.phoneNumber} <Asterisk>*</Asterisk>
          </label>
          <AnimatedBorder isLoading={isLoading}>
            <input
              name="phoneNumber"
              type="text"
              value={
                person.info && person.info.phoneNumber
                  ? person.info.phoneNumber
                  : ''
              }
              onChange={(e) =>
                onChange({
                  ...person,
                  info: {
                    ...person.info,
                    phoneNumber: e.target.value,
                  },
                })
              }
            />
          </AnimatedBorder>
        </InputWrap>
        <InputWrap>
          <label htmlFor="email">
            {transForm.email} <Asterisk>*</Asterisk>
          </label>
          <AnimatedBorder isLoading={isLoading}>
            <input
              name="email"
              type="text"
              value={person.info && person.info.email ? person.info.email : ''}
              onChange={(e) =>
                onChange({
                  ...person,
                  info: {
                    ...person.info,
                    email: e.target.value,
                  },
                })
              }
            />
          </AnimatedBorder>
        </InputWrap>
        <InputWrap>
          <label htmlFor="dateofbirth">
            {transForm.dateOfBirth} <Asterisk>*</Asterisk>
          </label>
          <AnimatedBorder isLoading={isLoading}>
            <input
              name="dateofbirth"
              type="date"
              value={getFormattedDate(person)}
              onChange={(e) =>
                onChange({
                  ...person,
                  info: {
                    ...person.info,
                    dateOfBirth: new Date(e.target.value).getTime(),
                  },
                })
              }
            />
          </AnimatedBorder>
        </InputWrap>
        {/* <InputWrap>
          <label htmlFor="address1">{transForm.line1}</label>
          <AnimatedBorder isLoading={isLoading}>
            <input
              name="address1"
              type="text"
              value={
                person.address && person.address.line1
                  ? person.address.line1
                  : person.providerInfo.address
              }
              onChange={(e) =>
                onChange({
                  ...person,
                  address: {
                    ...person.address,
                    line1: e.target.value,
                  },
                })
              }
              
            />
          </AnimatedBorder>
        </InputWrap>
        <InputWrap>
          <label htmlFor="address2">{transForm.line2}</label>
          <AnimatedBorder isLoading={isLoading}>
            <input
              name="address2"
              type="text"
              value={
                person.address && person.address.line2
                  ? person.address.line2
                  : ''
              }
              onChange={(e) =>
                onChange({
                  ...person,
                  address: {
                    ...person.address,
                    line2: e.target.value,
                  },
                })
              }
              
            />
          </AnimatedBorder>
        </InputWrap>
        <InputWrap>
          <label htmlFor="city">{transForm.city}</label>
          <AnimatedBorder isLoading={isLoading}>
            <input
              name="city"
              type="text"
              value={
                person.address && person.address.city ? person.address.city : ''
              }
              onChange={(e) =>
                onChange({
                  ...person,
                  address: {
                    ...person.address,
                    city: e.target.value,
                  },
                })
              }
              
            />
          </AnimatedBorder>
        </InputWrap>
        <InputWrap>
          <label htmlFor="postcode">{transForm.postcode}</label>
          <AnimatedBorder isLoading={isLoading}>
            <input
              name="postcode"
              type="text"
              value={
                person.address && person.address.postcode
                  ? person.address.postcode
                  : ''
              }
              onChange={(e) =>
                onChange({
                  ...person,
                  address: {
                    ...person.address,
                    postcode: e.target.value,
                  },
                })
              }
              
            />
          </AnimatedBorder>
        </InputWrap>
        <InputWrap>
          <label htmlFor="country">{transForm.country}</label>
          <AnimatedBorder isLoading={isLoading}>
            <CountrySelect
              name="country"
              value={
                person.address && person.address.country
                  ? person.address.country
                  : ''
              }
              onChange={(val) =>
                onChange({
                  ...person,
                  address: {
                    ...person.address,
                    country: val,
                  },
                })
              }
              
            />
          </AnimatedBorder>
        </InputWrap> */}
      </Container>
    )
  }
)

import React, { useContext } from 'react'
import { StoreContext } from '../../../components/App'
import { PageHeading } from '../../../components/PageHeading/PageHeading'
import { PersonalData } from './summary/PersonalData'
import { ToRight } from '../EnrollCompany.styles'
import { Boxed, Container, ProceedButton } from './enroll.styles'
import { CompanyData } from './summary/CompanyData'
import { Directors } from './summary/Directors'
import { Ubos } from './summary/Ubos'
import { useNavigate } from 'react-router-dom'

export const Summary = () => {
  const navigate = useNavigate()
  const store = useContext(StoreContext)
  const { theme } = store.AppState
  const {
    enrollCompanyPage: { summary: trans },
  } = store.TranslationsState.translations

  const handleProceed = () => {
    navigate('/enroll-company/success')
  }

  return (
    <Container>
      <PageHeading
        title={trans.title}
        subtitle={trans.subtitle}
        {...theme.pageHeading}
      />
      <Boxed>
        <PageHeading
          title={trans.personalData}
          {...theme.pageHeading}
          headerFontSize={18}
          margin="0"
        />
        <PersonalData initialEdit={false} />

        <PageHeading
          title={trans.companyData}
          {...theme.pageHeading}
          headerFontSize={18}
          margin="0"
        />
        <CompanyData initialEdit={false} />

        <PageHeading
          title={trans.directors}
          {...theme.pageHeading}
          headerFontSize={18}
          margin="0"
        />
        <Directors initialEdit={false} />

        <PageHeading
          title={trans.ubos}
          {...theme.pageHeading}
          headerFontSize={18}
          margin="0"
        />
        <Ubos initialEdit={false} />

        <ToRight>
          <ProceedButton onClick={handleProceed}>
            <span>{trans.continue}</span>
          </ProceedButton>
        </ToRight>
      </Boxed>
    </Container>
  )
}

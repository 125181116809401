import styled from 'styled-components'

interface ContainerProps {
  color?: string
}

export const Container = styled.div.attrs({})<ContainerProps>`
  box-sizing: border-box;
  padding: 24px 32px 32px;
  border: 1px solid #e9e7ee;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  max-width: 503px;
  width: 100%;
  position: relative;
  gap: 12px;

  h3 {
    font-family: 'Inter';
    font-size: 18px;
    color: ${(props) => (props.color ? props.color : '#0071f3')};
    font-weight: 600;
    padding: 0;
    margin: 0;
  }

  button.edit {
    position: absolute;
    top: 24px;
    right: 32px;
    padding: 0;
    border: 0;
    background-color: transparent;
  }

  div.icon-text {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  div.verification-info {
    display: flex;
    padding: 16px 24px 16px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;

    background: #f7f9fc;

    & > div {
      display: flex;
      gap: 8px;

      span {
        color: #565b60;
        line-height: 20px;

        &.label {
          font-weight: 400;
        }

        &.value {
          font-weight: 700;
        }
      }
    }

    button {
      display: flex;
      padding: 10px 24px;
      min-width: 120px;
      justify-content: center;
      align-items: center;
      border: 2px solid #cecbd6;
      background: #fff;
      border-radius: 100px;
      color: #0071f3;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
    }
  }

  input {
    padding: 10px 16px;
    font-family: 'Inter';
    font-size: 14px;
    color: #25292c;
    font-weight: 400;
    border-radius: 4px;
    border: 1px solid #b7bdc6;
    background: #fff;

    &:disabled {
      background: #f9f9f9;
      color: #b7bdc6;
    }
  }

  label {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 8px;
  }

  @media (max-width: 980px) {
    max-width: 100%;
  }
`

export const InputWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`

export const Asterisk = styled.span`
  color: red;
`
